const INCOME_TUTORIALS = {
    SELECTION: {
        Retired: 'xjXb5vnVQ8c',
        SelfEmployed: '9e61h5Y0wOM',
        Apprentice: 'qkh2ndOVpzc',
        IndividualEntrepreneur: 'obKhe2Gf330',
        DomesticEmployee: 'Ko2Ult8YuNA',
        Unemployed: 'fB6X0-V6oWA',
        PrivateEmployee: 'pwt9XpKSwv8',
        BusinessOwnerSimplifiedTax: 'WueBJCosPvU',
        InformalWorker: '97jBxkZaP1E',
        TemporaryDisabilityBenefit: 'byo6FQ7kjyE',
        LiberalProfessional: 'EiUlzAsIOfA',
        Alimony: 'Mw_iyIMg14s',
        PublicEmployee: '3ztrerrVze8'
    },
    INFORMATION: {
        Retired: 'bV_0QcKt9Bg',
        SelfEmployed: 'viDIeXat4_w',
        Apprentice: 'LlcSkP_Pbps',
        IndividualEntrepreneur: 'lEuJZZDpsbo',
        DomesticEmployee: 'KNsWxncHrbA',
        Unemployed: 'DtjXx8dd1cE',
        BusinessOwnerSimplifiedTax: 'I1OS4swIvE0',
        InformalWorker: 'Oz7dJUeuZJ4',
        TemporaryDisabilityBenefit: 'reFAXF7Ls20',
        LiberalProfessional: 'YGUVO8y_0HA',
        Alimony: 'PbSL9WvSNNM',
        PublicEmployee: '2EK9hg72ZNk'

    },
    MONTHS: {
        Retired: '5uFCt9TT26o',
        SelfEmployed: 'xecLmIWiwQk',
        Apprentice: 'IX6dXKZcLzA',
        IndividualEntrepreneur: 'FUalchpgPR0',
        DomesticEmployee: 'U5PsLwQ8I2k',
        PrivateEmployee: 'BPwiySLrGc8',
        BusinessOwnerSimplifiedTax: 'TySGMPOwQ9c',
        InformalWorker: '8u4LvNvgC7I',
        TemporaryDisabilityBenefit: 'UHogqqqMKEo',
        LiberalProfessional: 'EOR54pAyr0M',
        Alimony: 'cbd_u_4xjY0',
        PublicEmployee: 'Ge-GwvUpVg8'
    },
    GROSS: {
        Retired: '1KteMYw66G8',
        SelfEmployed: 'hlC6vyyX2KE',
        Apprentice: 'n8FLTdP_JPk',
        IndividualEntrepreneur: '8K4YcOgqGj4',
        DomesticEmployee: '5sl_8lGOLZc',
        PrivateEmployee: 'qmNEAk_18Bo',
        InformalWorker: 'bnHAkYS-J30',
        TemporaryDisabilityBenefit: 'Z_77i19a4WU',
        LiberalProfessional: 'nqTSnsr8oKc',
        Alimony: 'CVbqLle-Xfo',
        PublicEmployee: 'IeiKeMGsjGc'

    },
    FOOD: {
        Retired: 'MsLXLVnMWjI',
        Apprentice: '7G0U6URl31Q',
        DomesticEmployee: '2A1p8R99zR4',
        TemporaryDisabilityBenefit: 'looW62OalFg',
        PublicEmployee: 'H5T-5kLvPQw'

    },
    TRANSPORT: {
        Retired: 'vtBESt2GnOk',
        Apprentice: 'W_FrAbBE_Zg',
        DomesticEmployee: 'n1W0bDGbNa0',
        PrivateEmployee: 'bPqkCt44nGA',
        TemporaryDisabilityBenefit: 'MtYE0ZZ23u4',
        PublicEmployee: 'U98k6OYcahY'

    },
    REIMBURSEMENT: {
        Retired: 'O9Lf5-KRdLU',
        Apprentice: 'bExIm9elitw',
        DomesticEmployee: 'YEk8PwqipIo',
        PrivateEmployee: '7k96EWUoCHk',
        TemporaryDisabilityBenefit: 'IOcO0_4FQkA',
        PublicEmployee: 'rpt8ptIQP-I'

    },
    ADVANCED_PAYMENT: {
        Retired: '5ymKFokTORw',
        Apprentice: 'h4JGfBf-64w',
        DomesticEmployee: '8c0USmPzTOw',
        PrivateEmployee: 'ix2p2E0UViY',
        TemporaryDisabilityBenefit: 'o_uFLZGO_R4',
        PublicEmployee: '239dL51GbAM'

    },
    REVERSAL: {
        Retired: 'xkuTsxdrdh0',
        Apprentice: 'OQr5emX1CaA',
        PrivateEmployee: 'ozWeJI_blNo',
        PublicEmployee: 'jb-eMmuWQqo'

    },
    COMPENSATIONS: {
        Retired: 'ngk4JErtSA4',
        Apprentice: 'IkfHbBfFkrk',
        PrivateEmployee: 'MFPojhzmjDI',
        PublicEmployee: '9onR6tad1HM'

    },
    PENSION: {
        Retired: '40L6MSRS7lk',
        SelfEmployed: 'JOhcARFFJMo',
        Apprentice: '0npCR0WxS2M',
        IndividualEntrepreneur: 'MrPiXnCM7Lw',
        DomesticEmployee: 'JxWzUTztdw4',
        PrivateEmployee: 'Y6zPjmkUlhE',
        BusinessOwnerSimplifiedTax: 'dQBRpLHrNEg',
        InformalWorker: 'QjbagOIWFSA',
        TemporaryDisabilityBenefit: 'p0EbFlHNLnU',
        LiberalProfessional: '-AqksbZ1jgs',
        PublicEmployee: 'gWF-ywSAZYM'
    },
    DIVIDENDS: {
        BusinessOwnerSimplifiedTax: '-NKR_9-rgnY'
    },

    DOCUMENT: {
        Retired: 'eTeCCQhj20g',
        SelfEmployed: 'i3q-4-vKeBo',
        Apprentice: 'L6d5ohA-LKM',
        IndividualEntrepreneur: '3YDSs6V1SLA',
        DomesticEmployee: 'jo5kzubCT8c',
        PrivateEmployee: '5pYDKwN-e40',
        BusinessOwnerSimplifiedTax: 'CzC9ZM4RdsI',
        InformalWorker: 'j9mxCuWltpk',
        TemporaryDisabilityBenefit: '1ELC3cT8luQ',
        LiberalProfessional: 'Cg680gGcDGs',
        Alimony: '8vJ40tgRvso',
        PublicEmployee: 'EX3wl311Cwg'
    },
    FINISH: {
        Retired: 'iDf8yyza1mg',
        Apprentice: 'qkh2ndOVpzc'

    },

}

export default INCOME_TUTORIALS