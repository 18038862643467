export const STUDENT_SCHOLARSHIP_STATUS = {
    Active: "Active",
    Pending: "Pending",
    Renewed: "Renewed",
    Cancelled: "Cancelled",
}

export const STUDENT_SCHOLARSHIP_TRANSLATION = {
    Active: "Ativa",
    Pending: "Pendente",
    Renewed: "Renovada",
    Cancelled: "Cancelada",
}