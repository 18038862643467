const PROPERTY_STATUS = [
    { value: "OwnPaidOff", label: "Própria e quitada" },
    { value: "OwnFinanced", label: "Própria e financiada" },
    { value: "Rented", label: "Alugada" },
    { value: "ProvidedByEmployer", label: "Cedida pelo empregador" },
    { value: "ProvidedByFamily", label: "Cedida pela família" },
    { value: "ProvidedOtherWay", label: "Cedida de outra forma" },
    { value: "Irregular", label: "Irregular" },
];

export default PROPERTY_STATUS