import { FormStepperRoot } from "./Root";
import { FormStep } from "./Step";
import { StepperContainer } from "./Stepper";
import { StepView } from "./View";

export default {
    Root: FormStepperRoot,
    Stepper: StepperContainer,
    Step: FormStep,
    View: StepView
} 