const BANK_ACCOUNT_TYPES = [
    { value: 'CHECKING_ACCOUNT', label: 'Conta Corrente' },
    { value: 'SAVINGS_ACCOUNT', label: 'Conta Poupança' },
    { value: 'PAYMENT_ACCOUNT', label: 'Conta de Pagamento' },
    { value: 'SALARY_ACCOUNT', label: 'Conta Salário' },
    { value: 'STUDENT_ACCOUNT', label: 'Conta Estudantil' },
    { value: 'DIGITAL_ACCOUNT', label: 'Conta Digital' },
    { value: 'MINORS_ACCOUNT', label: 'Conta para Menores' },
    { value: 'BUSINESS_ACCOUNT', label: 'Conta Empresarial' },
    { value: 'JOINT_ACCOUNT', label: 'Conta Conjunta' }
];

export default BANK_ACCOUNT_TYPES