const ANNOUNCEMENT_TUTORIALS = {
    CREATE: {
        Initial: 'nAzVIMh_XFE',
        Courses: 'll4vlbyGBYw',
        Assist: '2q0o618S_YY',
        Finish: 'wyOWNE2AZlQ',
    },


}

export default ANNOUNCEMENT_TUTORIALS