const SCHOLARSHIP_OFFER = [
    { value: 'Law187Scholarship', label: 'Bolsa Lei 187 Integral' },
    { value: 'Law187ScholarshipPartial', label: 'Bolsa Lei 187 Parcial' },

    { value: 'StudentWithDisabilityPartial', label: 'Estudante com Deficiência Parcial' },
    { value: 'StudentWithDisability', label: 'Estudante com Deficiência Integral' },

    { value: 'FullTime', label: 'Tempo Integral (Integral)' },
    { value: 'FullTimePartial', label: 'Tempo Integral (Parcial)' },

    { value: 'EntityWorkers', label: 'Trabalhadores da Entidade Integral' },
    { value: 'EntityWorkersPartial', label: 'Trabalhadores da Entidade Parcial' }

];

export default SCHOLARSHIP_OFFER