const DECLARATIONS_TUTORIALS = {
     overview: "gnyde-u3z8k",
     form: "4BdBE6ddjLI",
     pension: "QjUfBx0aeL8",
     childPension: "SXyc1HggX4s",
     childSupport: "",
     childSupportDetails: "",
     pensionConfirmation: "7gLa1FXrR-k",
     addressProof: "t88vxkbbdEk",
     noAddressProof: "",
     rentedHouse: "",
     rentDetails: "",
     rentConfirmation: "",
     workCard: "F2cfJN5Xxfo",
     workCardUpload: "QGAWBEu39jM",
     workCardConfirmation: "",
     contributionStatement: "WIWLdTZi2Ww",
     stableUnion: "RQQ4MBbk2ww",
     stableUnionConfirmation: "",
     singleStatus: "hNcPOZ0QoBM",
     separationStatus: "JGT1bpmByKc",
     currentAddress: "",
     separationConfirmation: "",
     separationNoAddressConfirmation: "",
     incomeTaxExemption: "pUjTW2nNJw4",
     incomeTaxExemptionConfirmation: "",
     activity: "mPy6YTdg2hE",
     activityConfirmation: "6Y-DhnnKxFU",
     mei: "TTKAkxaTzak",
     meiConfirmation: "Nj9cgGBooaw",
     ruralWorker: "IPK1LAelpUo",
     ruralWorkerConfirmation: "",
     autonomo: "iZ5eb6GIJg8",
     autonomoConfirmation: "",
     empresario: "srIlw9c1trk",
     empresarioConfirmation: "",
     inactiveCompany: "SsBMJ3AOqes",
     inactiveCompanyConfirmation: "",
     rentIncome: "1QwzI17BY-c",
     rentIncomeDetails: "6p1TGVKvfno",
     rentIncomeConfirmation: "",
     vehicleOwnership: "PdwF8xIDdDE",
     familyIncomeChange: "Ff1pKcAyNk8",
     responsibilityConfirmation: "QUCw2ZHH8pA",
     witnesses: "",
     personalData: "E2ZZozXqZEw",
}
export default DECLARATIONS_TUTORIALS