const DISEASES = [
    { value: 'ALIENATION_MENTAL', label: 'Alienação Mental' },
    { value: 'CARDIOPATHY_SEVERE', label: 'Cardiopatia Grave' },
    { value: 'BLINDNESS', label: 'Cegueira' },
    { value: 'RADIATION_CONTAMINATION', label: 'Contaminação por Radiação' },
    { value: 'PARKINSONS_DISEASE', label: 'Doença de Parkinson' },
    { value: 'ANKYLOSING_SPONDYLITIS', label: 'Espondilite Anquilosante' },
    { value: 'PAGETS_DISEASE', label: 'Doença de Paget' },
    { value: 'HANSENS_DISEASE', label: 'Hanseníase (Lepra)' },
    { value: 'SEVERE_HEPATOPATHY', label: 'Hepatopatia Grave' },
    { value: 'SEVERE_NEPHROPATHY', label: 'Nefropatia Grave' },
    { value: 'PARALYSIS', label: 'Paralisia' },
    { value: 'ACTIVE_TUBERCULOSIS', label: 'Tuberculose Ativa' },
    { value: 'HIV_AIDS', label: 'HIV/AIDS' },
    { value: 'MALIGNANT_NEOPLASM', label: 'Neoplasma Maligno (Câncer)' },
    { value: 'TERMINAL_STAGE', label: 'Estágio Terminal' },
    { value: 'MICROCEPHALY', label: 'Microcefalia' },
    { value: 'AUTISM_SPECTRUM_DISORDER', label: 'Transtorno do Espectro Autista' },
    { value: 'RARE_DISEASE', label: 'Doença Rara' },
    { value: 'OTHER_HIGH_COST_DISEASE', label: 'Outra Doença de Alto Custo' }
]

export default DISEASES