const HOUSING_TUTORIALS = {
    STATUS: {
        OwnPaidOff: 'NEUngdS65h4',
        OwnFinanced: 'ylGinhXsIpA',
        Rented: 'afHkrCKBA50',
        ProvidedByEmployer: 'c1vn3chnSeQ',
        ProvidedByFamily: '6KH0l-qSyR4',
        ProvidedOtherWay: 'ikuuE5nqWpU',
        Irregular: 'jYj6nwog2vM',
    }
}

export default HOUSING_TUTORIALS