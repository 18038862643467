export const SCHOLARSHIP_STATUS = {
    "SELECTED": "SELECTED",
    "REGISTERED": "REGISTERED",
    "NOT_REGISTERED": "NOT_REGISTERED",
    "GAVEUP": "GAVEUP",
}
export const SCHOLARSHIP_STATUS_TRANSLATION = {
    "SELECTED": "Selecionado",
    "REGISTERED": "Matriculado",
    "NOT_REGISTERED": "Não matriculado",
    "GAVEUP": "Desistência",
}