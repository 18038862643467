const SCHOLARSHIP_TYPE = [
    { value: 'PROUNIFull', label: 'PROUNI Integral' },
    { value: 'PROUNIPartial', label: 'PROUNI Parcial' },
    { value: 'StateGovernment', label: 'Governo Estadual' },
    { value: 'CityGovernment', label: 'Governo Municipal' },
    { value: 'ExternalEntities', label: 'Entidades Externas' },
    { value: 'HigherEduInstitutionFull', label: 'Instituição de Ensino Superior Integral' },
    { value: 'HigherEduInstitutionPartial', label: 'Instituição de Ensino Superior Parcial' },
    { value: 'HigherEduInstitutionWorkers', label: 'Trabalhadores da Instituição de Ensino Superior' },
    { value: 'PostgraduateStrictoSensu', label: 'Pós-Graduação Stricto Sensu' }
];

export default SCHOLARSHIP_TYPE