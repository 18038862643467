export const STUDENT_RENEW_STATUS = {
    Done: "Done",
    Pending: "Pending",
    Late: "Late",
}

export const STUDENT_RENEW_TRANSLATION = {
    Done: "Concluída",
    Pending: "Pendente",
    Late: "Atrasada",
}