const FAMILY_RELATIONSHIP = [
    { value: "Wife", label: "Esposa" },
    { value: "Husband", label: "Marido" },
    { value: "Father", label: "Pai" },
    { value: "Mother", label: "Mãe" },
    { value: "Stepfather", label: "Padrasto" },
    { value: "Stepmother", label: "Madrasta" },
    { value: "Sibling", label: "Irmão/Irmã" },
    { value: "Grandparent", label: "Avô/Avó" },
    { value: "Child", label: "Filho/Filha" },
    { value: "Other", label: "Outro" },
];

export default FAMILY_RELATIONSHIP