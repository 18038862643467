const SUBSCRIPTION_TUTORIALS = {
    CANDIDATEINFO: {
        AdditionalDocuments: 'CBRve1ujNuo',
        Additional: 'ttXDRIIX_Rg',
        Benefits: '0db64lU0O0A',

    },


}

export default SUBSCRIPTION_TUTORIALS