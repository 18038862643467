export const CALL_STATUS = {
    "CLOSED": "CLOSED",
    "OPEN": "OPEN",
    "INPROGRESS": "INPROGRESS",
}

export const CALL_STATUS_TRANSLATION = {

    [CALL_STATUS.CLOSED]: "FINALIZADO",
    [CALL_STATUS.OPEN]: "ABERTO",
    [CALL_STATUS.INPROGRESS]: "EM ANDAMENTO",
}
