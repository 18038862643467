const SCHOLARSHIP = [
    { value: "Illiterate", label: "Analfabeto" },
    { value: "ElementarySchool", label: "Ensino Fundamental" },
    { value: "HighSchool", label: "Ensino Médio" },
    { value: "CollegeGraduate", label: "Graduação" },
    { value: "CollegeUndergraduate", label: "Graduação Incompleta" },
    { value: "Postgraduate", label: "Pós-Graduação" },
    { value: "Masters", label: "Mestrado" },
    { value: "Doctorate", label: "Doutorado" },
    { value: "PostDoctorate", label: "Pós-Doutorado" },
];

export default SCHOLARSHIP