const AI_STATUS = {
    RELIABLE: "RELIABLE",
    LESS_RELIABLE: "LESS_RELIABLE",
    UNRELIABLE: "UNRELIABLE",
}

const AI_STATUS_TRANSLATION = {
    RELIABLE: "Confiável",
    LESS_RELIABLE: "Pouco confiável",
    UNRELIABLE: "Não confiável",
}

export {
    AI_STATUS,
    AI_STATUS_TRANSLATION
}