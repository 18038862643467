import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import ButtonBase from "Components/ButtonBase";
import FormCheckbox from 'Components/FormCheckbox';
import InputForm from 'Components/InputForm';
import MoneyFormInput from 'Components/MoneyFormInput';
import useControlForm from 'hooks/useControlForm';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { formatCPF } from 'utils/format-cpf';
import declarationAtom from '../../atoms/declarationAtom';
import commonStyles from '../../styles.module.scss';
import pensionSchema from './pension-schema';

export default function Declaration_Pension({ onBack, onNext }) {
    // const [receivesPension, setReceivesPension] = useState(null);

    const [declarationData, setDeclarationData] = useRecoilState(declarationAtom);
    const { control, formState: { isValid }, trigger, getValues, watch, resetField } = useControlForm({
        schema: pensionSchema,
        defaultValues: {
            receivesPension: null,
            payerName: '',
            payerCpf: '',
            amount: ''
        },
        initialData: declarationData?.pensionData
    })
    useEffect(() => {
        if (!watch("receivesPension")) {
            resetField("payerName", { defaultValue: '' })
            resetField("payerCpf", { defaultValue: '' })
            resetField("amount", { defaultValue: '' })
        }
    }, [watch("receivesPension")])
    // useEffect(() => {
    //     if (declarationData.pensionData) {
    //         const { pensionData } = declarationData
    //         setReceivesPension(pensionData.receivesPension)
    //         setPayerName(pensionData.payerName)
    //         setPayerCpf(pensionData.payerCpf)
    //         setAmount(pensionData.amount)
    //     }
    // }, []);
    // const handleRadioChange = (event) => {
    //     setReceivesPension(event.target.value === 'yes');
    // };

    const handleSave = () => {
        if (!isValid) {
            trigger()
            return
        }


        const pensionData = {
            // receivesPension,
            ...getValues()
        };
        // localStorage.setItem('pensionData', JSON.stringify(pensionData));
        setDeclarationData((prev) => {
            return ({ ...prev, pensionData })
        })
        onNext();
    };


    if (!declarationData) {
        return <p>Carregando...</p>;
    }

    return (
        <div className={commonStyles.declarationForm}>
            <h2 className={commonStyles.declarationFormTitle}>DECLARAÇÕES PARA FINS DE PROCESSO SELETIVO CEBAS</h2>
            <h3 className={commonStyles.declarationFormSubTitle}>RECEBIMENTO OU AUSÊNCIA DE RECEBIMENTO DE PENSÃO ALIMENTÍCIA</h3>
            <h3 className={commonStyles.declarationFormNameTitle}>{declarationData.name}</h3>
            <div className={commonStyles.declarationContent}>
                <FormCheckbox
                    label={'A - Você recebe pensão alimentícia?'}
                    control={control}
                    name={"receivesPension"}
                    value={watch("receivesPension")}
                />

                {watch("receivesPension") && (
                    <div className={commonStyles.additionalFields}>
                        <InputForm control={control} label={'Nome do pagador'} name="payerName" />
                        <InputForm control={control} label={'CPF do pagador'} name="payerCpf" transform={(e) => formatCPF(e.target.value)} />
                        <MoneyFormInput control={control} label={'Valor da pensão'} name="amount" />
                    </div>
                )}
            </div>
            <div className={commonStyles.navigationButtons}>
                <ButtonBase onClick={onBack}><Arrow width="30px" style={{ transform: "rotateZ(180deg)" }} /></ButtonBase>
                <ButtonBase
                    label="Salvar"
                    onClick={handleSave}
                />
                {/* <ButtonBase
                    onClick={handleSave}
                >
                    <Arrow width="30px" />
                </ButtonBase> */}
            </div>
        </div>
    );
}
