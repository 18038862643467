const NUMBER_ROOMS = [
    { value: "One", label: "Um" },
    { value: "Two", label: "Dois" },
    { value: "Three", label: "Três" },
    { value: "Four", label: "Quatro" },
    { value: "Five", label: "Cinco" },
    { value: "Six", label: "Seis" },
    { value: "Seven", label: "Sete" },
    { value: "Eight", label: "Oito" },
    { value: "Nine", label: "Nove" },
    { value: "Ten", label: "Dez" },
    { value: "Eleven", label: "Onze" },
    { value: "Twelve", label: "Doze" },
];

export default NUMBER_ROOMS