const METADATA_TYPE_MAPPER = {
    MARRIED: 'Certidão de casamento',
    SINGLE: 'Certidão de nascimento',
    WIDOW: 'Certidão de casamento com anotação de viuvez',
    DIVORCED: 'Certidão de casamento com averbação de divórcio',
    STABLE: 'Declaração de união estável',
    ID: 'Documento de identificação',
    DRIVER: 'Carteira de motorista',
    FUNCTIONAL: 'Carteira funcional',
    ARMY: 'Identidade militar',
    FOREIGN: 'Registro  de estrangeiro',
    PASSPORT: 'Passaporte',
    WORKCARD: 'Carteira de trabalho',
    RESIDENCE: 'Comprovante de residência',
    STATEMENT: 'Extrato bancário',
    REGISTRATO: 'Registrato',
    EXAM: 'Exame/laudo',
    REGISTERPROOF: 'Comprovante de cadastramento',
    PROLABORE: 'Pró-labore',
    DIVIDENDS: 'Dividendos',
    DECORE: 'Decore',
    CNIS: 'CNIS',
    IR: 'Imposto de Renda',
    MEI: 'Cadastro MEI',
    WORKCARD: 'Carteira de trabalho',
    DECLARATIONS: 'Declarações',
    PIX: 'Pix',
    INCOMEPROOF: 'Comprovante de renda',
    HISTORY: 'Histórico escolar',
    ADDITIONAL_ASSISTANT: 'Documento adicional enviado pela assistente',
}

export default METADATA_TYPE_MAPPER