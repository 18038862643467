const INCOME_SOURCE = [
    { value: "PrivateEmployee", label: "Empregado Privado" },
    { value: "PublicEmployee", label: "Empregado Público" },
    { value: "DomesticEmployee", label: "Empregado Doméstico" },
    { value: "TemporaryRuralEmployee", label: "Empregado Rural Temporário" },
    { value: "BusinessOwnerSimplifiedTax", label: "Empresário - Regime Simples" },
    { value: "BusinessOwner", label: "Empresário" },
    { value: "IndividualEntrepreneur", label: "Empreendedor Individual" },
    { value: "SelfEmployed", label: "Autônomo" },
    { value: "Retired", label: "Aposentado" },
    { value: "Pensioner", label: "Pensionista" },
    { value: "IncomeTransfer", label: "Programas de Transferência de Renda" },
    { value: "Apprentice", label: "Aprendiz" },
    { value: "Volunteer", label: "Voluntário" },
    { value: "RentalIncome", label: "Renda de Aluguel" },
    { value: "Student", label: "Estudante" },
    { value: "InformalWorker", label: "Trabalhador Informal" },
    { value: "Unemployed", label: "Desempregado" },
    {
        value: "TemporaryDisabilityBenefit",
        label: "Benefício por Incapacidade Temporária",
    },
    { value: "LiberalProfessional", label: "Profissional Liberal" },
    { value: "FinancialHelpFromOthers", label: "Ajuda Financeira de Terceiros" },
    { value: "Alimony", label: "Pensão Alimentícia" },
    { value: "PrivatePension", label: "Previdência Privada" },
];

export default INCOME_SOURCE