const METADATA_FILE_TYPE = {
    MARITAL: {
        MARRIED: 'MARRIED',
        BIRTH: 'BIRTH',
        WIDOW: 'WIDOW',
        SINGLE: 'SINGLE',
        DIVORCED: 'DIVORCED',
        STABLE: 'STABLE',
    },
    DOCUMENT: {
        ID: 'ID',
        DRIVER: 'DRIVER',
        FUNCTIONAL: 'FUNCTIONAL',
        ARMY: 'ARMY',
        FOREIGN: 'FOREIGN',
        PASSPORT: 'PASSPORT',
        WORKCARD: 'WORKCARD',
        REGISTERPROOF: 'REGISTERPROOF'
    },
    RESIDENCE: {
        RESIDENCE: 'RESIDENCE',
        RENTCONTRACT: 'RENTCONTRACT',
        GIVENPROPERTY: 'GIVENPROPERTY'
    },
    BANK: {
        STATEMENT: 'STATEMENT',
        REGISTRATO: 'REGISTRATO',
        PIX: 'PIX',
        PROLABORE: 'PROLABORE',
        DIVIDENDS: 'DIVIDENDS',
        DECORE: 'DECORE',
        INCOMEPROOF: 'INCOMEPROOF'
    },
    HEALTH: {
        EXAM: 'EXAM'
    },
    DECLARATIONS: {
        CNIS: 'CNIS',
        IR: 'IR',
        MEI: 'MEI',
        WORKCARD: 'WORKCARD',
        DECLARATIONS: 'DECLARATIONS'
    },
    STUDENT: {
        HISTORY: "HISTORY",
        ADDITIONAL_ASSISTANT: "ADDITIONAL_ASSITANT"
    }
}


export default METADATA_FILE_TYPE
