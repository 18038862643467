const METADATA_FILE_CATEGORY = {
    Identity: "IDENTITY",
    Income: "INCOME",
    Registrato: "REGISTRATO",
    Finance: "Finance",
    Statement: "STATEMENT",
    Disease: "DISEASE",
    Medication: "MEDICATION",
    Declarations: "DECLARATIONS",
    Residence: "RESIDENCE",
    Student: "STUDENT"
}
export default METADATA_FILE_CATEGORY